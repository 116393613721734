<script setup lang="ts">
  import { type PropType } from 'vue';
  import type { PotaParkType } from '@/types';

  const props = defineProps({
    park: {
      type: Object as PropType<PotaParkType>,
      required: true
    }
  });
</script>

<template>
  <div data-testid="toast">
    <div data-testid="toast__header" class="font-bold text-sm mb-1 flex">
      <span data-testid="toast__badge"
        class="inline-flex items-center text-green-600 rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase">
        POTA
      </span>
      <span data-testid="toast__title" class="ml-2 text-base font-mono">
        {{ props.park.id }}
      </span>
    </div>
    <div data-testid="toast__body">
      <div class="font-semibold titlecase text-sm">{{ props.park.name }} </div>
      <div class="font-medium titlecase text-sm">
        Location: {{ props.park.location }} {{ props.park.grid }}
      </div>
    </div>
  </div>
</template>
