// ProfilePouchRepo.ts
import PouchDB, { ensureIndexes } from "@/repos/PouchDB";
import type { QsoType, LogbookType, PaginationResult } from "@/types";

import { v4 as uuidv4 } from "uuid";
import { QSO_ID_PREFIX } from "@/constants";
import { normalizeQso } from "@/utils/normalizeQso";
import type { QsoRepoInterface } from "@/interfaces";
interface QsoSelector {
  _id: { $gte: string; $lte: string };
  qsoDateTime: { $exists: boolean };
  $or?: Array<{ [key: string]: { $regex: RegExp } }>;
}
function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
const QsoPouchRepo: QsoRepoInterface = {
  async create(params: { attrs: Partial<QsoType>, logbook: LogbookType }): Promise<QsoType> {
    const qsoId = `${QSO_ID_PREFIX}:${params.logbook._id}:${uuidv4()}`;
    const createdAt = new Date().toISOString();
    const newQso = {
      _id: qsoId,
      createdAt,
      ...params.attrs,
    } as QsoType;
    const normalizedQso = normalizeQso(newQso);
    await PouchDB.upsert(qsoId, () => normalizedQso);
    return PouchDB.get(qsoId);
  },
  async update(qso: QsoType): Promise<QsoType> {
    const qsoId = qso._id;
    const newQso = {
      ...qso,
      updatedAt: new Date().toISOString(),
    } as QsoType;
    await PouchDB.upsert(qsoId, () => normalizeQso(newQso));
    return PouchDB.get(qsoId);
  },


  async findAllByLogbook(logbookId: string): Promise<QsoType[]> {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${QSO_ID_PREFIX}:${logbookId}:`,
      endkey: `${QSO_ID_PREFIX}:${logbookId}:\ufff0`,
    });
    return result.rows
      .map((row) => row.doc as unknown as QsoType)
      .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
  },

  async countAllByLogbook(logbookId: string): Promise<number> {
    const result = await PouchDB.allDocs({
      startkey: `${QSO_ID_PREFIX}:${logbookId}:`,
      endkey: `${QSO_ID_PREFIX}:${logbookId}:\ufff0`,
    });
    return result.rows.length;
  },

  // deleteAllByLogbook pulls all QSO docs associated with a logbookId then
  // deletes them by doing a bulk operation to set _deleted: true on each.
  async deleteAllByLogbook(logbookId: string): Promise<any> {
    const result = await PouchDB.allDocs({
      startkey: `${QSO_ID_PREFIX}:${logbookId}:`,
      endkey: `${QSO_ID_PREFIX}:${logbookId}:\ufff0`,
    });

    const docsToDelete = result.rows
      .map((row) => ({ _id: row.id, _rev: row.value?.rev, _deleted: true }))

    return PouchDB.bulkDocs(docsToDelete)
  },

  async findAll(): Promise<QsoType[]> {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${QSO_ID_PREFIX}:`,
      endkey: `${QSO_ID_PREFIX}:\ufff0`,
    });
    return result.rows
      .map((row) => row.doc as unknown as QsoType)
      .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
  },

  async findOne(id: string): Promise<QsoType> {
    return PouchDB.get(id);
  },
  async delete(id: string): Promise<QsoType> {
    const doc = await PouchDB.get(id);
    await PouchDB.remove(doc);
    return Promise.resolve(doc as unknown as QsoType)
  },

  async countAll(): Promise<number> {
    const result = await PouchDB.allDocs({
      startkey: `${QSO_ID_PREFIX}:`,
      endkey: `${QSO_ID_PREFIX}:\ufff0`,
      include_docs: false,
    });
    return result.rows.length;
  },

  async getPageCount(limit: number): Promise<number> {
    const totalDocs = await this.countAll();
    return Math.ceil(totalDocs / limit);
  },

  async fetchAllWithPaging(
    page: number,
    limit: number,
    searchQuery?: string
  ): Promise<PaginationResult<QsoType>> {
    await ensureIndexes();

    // Step 1: Set the base selector for filtering QSOs by prefix
    const selector: QsoSelector = {
      _id: { $gte: `${QSO_ID_PREFIX}:`, $lte: `${QSO_ID_PREFIX}:\ufff0` },  // Filter by QSO prefix
      qsoDateTime: { $exists: true },  // Ensure qsoDateTime field exists
    };

    // Step 2: If a search term is provided, add search on fields like call or parkRef


    if (searchQuery) {
      const escapedQuery = escapeRegExp(searchQuery);
      const regex = new RegExp(escapedQuery, 'i');
      selector.$or = [
        { call: { $regex: regex } },
        { potaRef: { $regex: regex } },
        { name: { $regex: regex } },
      ];
    }

    // Step 3: Count total QSOs with the QSO_ID_PREFIX
    const totalRecords = await this.countAll()

    // Step 4: Calculate total pages
    const totalPages = Math.ceil(totalRecords / limit);

    // Ensure the current page is within bounds
    const currentPage = Math.max(1, Math.min(page, totalPages));

    // Step 5: Calculate how many documents to skip
    const skip = (currentPage - 1) * limit;

    // Step 6: Fetch the paginated documents for the current page with sorting by qsoDateTime
    const result = await PouchDB.find({
      selector,
      sort: [{ qsoDateTime: 'desc' }],  // Sort by qsoDateTime in descending order
      limit: limit,                    // Apply pagination limit
      skip: skip                       // Skip the number of records for pagination
    });

    const docs = result.docs as QsoType[];

    // Step 7: Determine if there are previous/next pages
    const hasNextPage = currentPage < totalPages;
    const hasPrevPage = currentPage > 1;

    // Step 8: Return paginated results with metadata
    return {
      docs,           // The QSO documents for the current page
      currentPage,    // The current page number
      totalPages,     // The total number of pages
      hasNextPage,    // Whether there's a next page
      hasPrevPage,    // Whether there's a previous page
      totalRecords,   // The total number of matching QSOs
    };
  }
};

export default QsoPouchRepo;
