import type { ProfileInterface } from "@/types";
import { ProfilePouchRepo } from "@/repos/ProfilePouchRepo";

const ProfileService = {
  create(profile: Partial<ProfileInterface>): Promise<ProfileInterface> {
    return ProfilePouchRepo.create(profile);
  },
  findAll(): Promise<ProfileInterface[]> {
    return ProfilePouchRepo.findAll();
  },
  update(profile: ProfileInterface): Promise<ProfileInterface> {
    return ProfilePouchRepo.update(profile);
  },
  findOne(id: string): Promise<ProfileInterface> {
    return ProfilePouchRepo.findOne(id);
  }
};

export default ProfileService;
