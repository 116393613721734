import type { LookupServiceProviderType } from "@/types/LookupServiceProviderType";
import type { QsoTableColumnVisibilityInterface } from "@/types/QsoTableColumnVisibilityInterface";
import packageJson from "../package.json";
export const APP_VERSION = packageJson.version;
interface BandRange {
  name: string;
  lower: number;
  upper: number;
}
export const LOGBOOK_TEMPLATE_NAMES: Record<string, string> = {
  GENERIC: "Generic",
  POTA: "POTA",
  CUSTOM: "Custom",
};

export const LOGBOOK_TEMPLATE_OPTIONS = [
  { value: 'GENERIC', label: 'Generic' },
  { value: 'POTA', label: 'Parks on the Air' },
]

export const QSO_TABLE_COLUMN_GROUPS: Record<
  string,
  QsoTableColumnVisibilityInterface
> = {
  ALL: {
    qsoDateTime: true,
    call: true,
    rstSent: false,
    rstRcvd: false,
    name: true,
    state: true,
    freq: true,
    band: true,
    mode: true,
    potaRef: true,
  },
  GENERIC: {
    qsoDateTime: true,
    call: true,
    rstSent: true,
    rstRcvd: true,
    name: false,
    state: true,
    freq: true,
    band: true,
    mode: true,
    potaRef: false,
  },
  POTA: {
    qsoDateTime: true,
    call: true,
    rstSent: true,
    rstRcvd: true,
    name: false,
    state: true,
    freq: true,
    band: true,
    mode: true,
    potaRef: true,
  },
};

type TemplateIconAndColor = {
  icon: string;
  color: string;
};

export const LOGBOOK_TEMPLATE_ICONS_AND_COLORS: Record<
  string,
  TemplateIconAndColor
> = {
  GENERIC: { icon: "fa-duotone fa-atlas", color: "gray" },
  POTA: { icon: "fa-duotone fa-tree-alt", color: "green" },
  CUSTOM: { icon: "fa-duotone fa-user", color: "brand-primary" },
};

export const LOGBOOK_TEMPLATES = Object.keys(LOGBOOK_TEMPLATE_NAMES);
export const LOGBOOK_ID_PREFIX = "LOGBOOK";

export const PROFILE_ID_PREFIX = "PROFILE";
export const QSO_ID_PREFIX = "QSO";

export const VALID_TIME_REGEX = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;

export const LOOKUP_SERVICES: Record<LookupServiceProviderType, string> = {
  HAMDB: "HamDB.org",
  QRZ: "QRZ.com",
  HAMQTH: "HamQTH.com",
};

export const LANGUAGE_OPTIONS: Record<string, string> = {
  en: "English",
};
export const DISTANCE_UNIT_OPTIONS: Record<string, string> = {
  miles: "Miles",
  kilometers: "Kilometers",
};

export const BANDS: string[] = [
  "2200m",
  "160m",
  "80m",
  "60m",
  "40m",
  "30m",
  "20m",
  "17m",
  "15m",
  "12m",
  "10m",
  "6m",
  "4m",
  "2m",
  "1.25m",
  "70cm",
  "33cm",
  "23cm",
  "13cm",
  "9cm",
  "6cm",
  "3cm",
];

export const BAND_RANGES: BandRange[] = [
  { name: "2200m", lower: 135.7, upper: 137.8 },
  { name: "160m", lower: 1800, upper: 2000 },
  { name: "80m", lower: 3500, upper: 4000 },
  { name: "60m", lower: 5330, upper: 5405 },
  { name: "40m", lower: 7000, upper: 7300 },
  { name: "30m", lower: 10100, upper: 10150 },
  { name: "20m", lower: 14000, upper: 14350 },
  { name: "17m", lower: 18000, upper: 18168 },
  { name: "15m", lower: 21000, upper: 21450 },
  { name: "12m", lower: 24890, upper: 24990 },
  { name: "10m", lower: 28000, upper: 29700 },
  { name: "6m", lower: 50000, upper: 54000 },
  { name: "4m", lower: 70000, upper: 70500 },
  { name: "2m", lower: 144000, upper: 148000 },
  { name: "1.25m", lower: 220000, upper: 225000 },
  { name: "70cm", lower: 420000, upper: 450000 },
  { name: "33cm", lower: 902000, upper: 928000 },
  { name: "23cm", lower: 1240000, upper: 1300000 },
  { name: "13cm", lower: 2300000, upper: 2450000 },
  { name: "9cm", lower: 3400000, upper: 3475000 },
  { name: "6cm", lower: 5650000, upper: 5925000 },
  { name: "3cm", lower: 10000000, upper: 10500000 },
];

export const MODES: Record<string, string> = {
  SSB: "SSB",
  CW: "CW",
  AM: "AM",
  FM: "FM",
  RTTY: "RTTY",
  FT8: "FT8",
  SSTV: "SSTV",
  C4FM: "C4FM",
  JS8: "MFSK---JS8",
  FT4: "MFSK---FT4",
  PSK31: "PSK---PSK31",
  "Digital Voice": "DIGITALVOICE",
  DSTAR: "DSTAR",
};

export const MODE_INPUT_OPTIONS = Object.entries(MODES).map(
  ([label, value]) => ({
    label,
    value,
  })
);


// [{value: "en", label: "English" }],
// 			{ value: "de", label: "Deutsche" },
// 			{ value: "es", label: "Español" },
// 			{ value: "pt", text: "Português" },
// 			{ value: "ru", text: "Pусский" }
// [
//   { label: 'France', value: 'fr', attrs: { disabled: true } },
//   { label: 'Germany', value: 'de', attrs: { disabled: true } },
//   { label: 'Spain', value: 'es', attrs: { disabled: true } },
//   { label: 'Italy', value: 'ie' },
//   { label: 'Greece', value: 'gr', attrs: { disabled: true } },
// ]
/* spell-checker: disable */
export const adifFields = [
  "ADDRESS",
  "ADDRESS_INTL",
  "AGE",
  "ALTITUDE",
  "ANT_AZ",
  "ANT_EL",
  "ANT_PATH",
  "ARRL_SECT",
  "AWARD_GRANTED",
  "AWARD_SUBMITTED",
  "A_INDEX",
  "BAND",
  "BAND_RX",
  "CALL",
  "CHECK",
  "CLASS",
  "CLUBLOG_QSO_UPLOAD_DATE",
  "CLUBLOG_QSO_UPLOAD_STATUS",
  "CNTY",
  "COMMENT",
  "COMMENT_INTL",
  "CONT",
  "CONTACTED_OP",
  "CONTEST_ID",
  "COUNTRY",
  "COUNTRY_INTL",
  "CQZ",
  "CREDIT_SUBMITTED",
  "CREDIT_GRANTED",
  "DARC_DOK",
  "DISTANCE",
  "DXCC",
  "EMAIL",
  "EQ_CALL",
  "EQSL_QSLRDATE",
  "EQSL_QSLSDATE",
  "EQSL_QSL_RCVD",
  "EQSL_QSL_SENT",
  "FISTS",
  "FISTS_CC",
  "FORCE_INIT",
  "FREQ",
  "FREQ_RX",
  "GRIDSQUARE",
  "GRIDSQUARE_EXT",
  "GUEST_OP",
  "HAMLOGEU_QSO_UPLOAD_DATE",
  "HAMLOGEU_QSO_UPLOAD_STATUS",
  "HAMQTH_QSO_UPLOAD_DATE",
  "HAMQTH_QSO_UPLOAD_STATUS",
  "HRDLOG_QSO_UPLOAD_DATE",
  "HRDLOG_QSO_UPLOAD_STATUS",
  "IOTA",
  "IOTA_ISLAND_ID",
  "ITUZ",
  "K_INDEX",
  "LAT",
  "LON",
  "LOTW_QSLRDATE",
  "LOTW_QSLSDATE",
  "LOTW_QSL_RCVD",
  "LOTW_QSL_SENT",
  "MAX_BURSTS",
  "MODE",
  "MS_SHOWER",
  "MY_ALTITUDE",
  "MY_ANTENNA",
  "MY_ANTENNA_INTL",
  "MY_ARRL_SECT",
  "MY_CITY",
  "MY_CITY_INTL",
  "MY_CNTY",
  "MY_COUNTRY",
  "MY_COUNTRY_INTL",
  "MY_CQ_ZONE",
  "MY_DXCC",
  "MY_FISTS",
  "MY_GRIDSQUARE",
  "MY_GRIDSQUARE_EXT",
  "MY_IOTA",
  "MY_IOTA_ISLAND_ID",
  "MY_ITU_ZONE",
  "MY_LAT",
  "MY_LON",
  "MY_NAME",
  "MY_NAME_INTL",
  "MY_POSTAL_CODE",
  "MY_POSTAL_CODE_INTL",
  "MY_POTA_REF",
  "MY_RIG",
  "MY_RIG_INTL",
  "MY_SIG",
  "MY_SIG_INTL",
  "MY_SIG_INFO",
  "MY_SIG_INFO_INTL",
  "MY_SOTA_REF",
  "MY_STATE",
  "MY_STREET",
  "MY_STREET_INTL",
  "MY_USACA_COUNTIES",
  "MY_VUCC_GRIDS",
  "MY_WWFF_REF",
  "NAME",
  "NAME_INTL",
  "NOTES",
  "NOTES_INTL",
  "NR_BURSTS",
  "NR_PINGS",
  "OPERATOR",
  "OWNER_CALLSIGN",
  "PFX",
  "POTA_REF",
  "PRECEDENCE",
  "PROP_MODE",
  "PUBLIC_KEY",
  "QRZCOM_QSO_UPLOAD_DATE",
  "QRZCOM_QSO_UPLOAD_STATUS",
  "QSLMSG",
  "QSLMSG_INTL",
  "QSLRDATE",
  "QSLSDATE",
  "QSL_RCVD",
  "QSL_RCVD_VIA",
  "QSL_SENT",
  "QSL_SENT_VIA",
  "QSL_VIA",
  "QSO_COMPLETE",
  "QSO_DATE",
  "QSO_DATE_OFF",
  "QSO_RANDOM",
  "QTH",
  "QTH_INTL",
  "REGION",
  "RIG",
  "RIG_INTL",
  "RST_RCVD",
  "RST_SENT",
  "RX_PWR",
  "SAT_MODE",
  "SAT_NAME",
  "SFI",
  "SIG",
  "SIG_INTL",
  "SIG_INFO",
  "SIG_INFO_INTL",
  "SILENT_KEY",
  "SKCC",
  "SOTA_REF",
  "SRX",
  "SRX_STRING",
  "STATE",
  "STATION_CALLSIGN",
  "STX",
  "STX_STRING",
  "SUBMODE",
  "SWL",
  "TEN_TEN",
  "TIME_OFF",
  "TIME_ON",
  "TX_PWR",
  "UKSMG",
  "USACA_COUNTIES",
  "VE_PROV",
  "VUCC_GRIDS",
  "WEB",
  "WWFF_REF"
];
/* spell-checker: enable */