import type {
	LookupServiceResponseType, 
	HamDbResponseType,
	HamQthResponseType,
 	LookupServiceProviderType,
	QrzLookupResponseType
}  from "@/types";

import HamDbResponseToLogbookResponseTransformer from "@/transformers/HamDbResponseToLogbookResponseTransformer";
import HamQthResponseToLogbookResponseTransformer from "@/transformers/HamQthResponseToLogbookResponseTransformer";
import QrzResponseToLogbookResponseTransformer from "@/transformers/QrzResponseToLogbookResponseTransformer";

import { trimObjectValues } from "@/utils/formatters";

type TransformFunctionType = {
  [key in LookupServiceProviderType]: (response: any) => LookupServiceResponseType;
};

type ResponseType = HamDbResponseType | HamQthResponseType | QrzLookupResponseType

const transformFunctions: TransformFunctionType = {
  HAMDB: HamDbResponseToLogbookResponseTransformer,
  HAMQTH: HamQthResponseToLogbookResponseTransformer,
  QRZ: QrzResponseToLogbookResponseTransformer,
};
function normalizeCommonFields(
  response: Partial<LookupServiceResponseType>
): LookupServiceResponseType {
  const trimmedResponse = trimObjectValues(response) as LookupServiceResponseType;
  return {
    ...trimmedResponse,
    callsign: trimmedResponse.callsign?.toUpperCase(),
    fullName: trimmedResponse.fullName?.trim(),
    firstName: trimmedResponse.firstName?.trim() ?? "",
    lastName: trimmedResponse.lastName?.trim() ?? "",
    address1: trimmedResponse.address1?.toUpperCase() ?? "",
    address2: trimmedResponse.address2?.toUpperCase() ?? "",
    city: trimmedResponse.city?.toUpperCase() ?? "",
    country: trimmedResponse.country?.toUpperCase() ?? "",
    county: trimmedResponse.county?.toUpperCase() ?? "",
  };
}

export default function LookupServiceResponseTransformer(
  response: ResponseType,
  service: LookupServiceProviderType
): LookupServiceResponseType {
  const transformFunction = transformFunctions[service];

  if (!transformFunction) {
    throw new Error(`No transform function defined for service: ${service}`);
  }

  const transformRespone = transformFunction(response);
  return normalizeCommonFields(transformRespone);
}
