import type { QsoType, LogbookType, PaginationResult } from "@/types";

import QsoPouchRepo from "@/repos/QsoPouchRepo";
import type { QsoServiceInterface } from "@/interfaces";

const QsoService: QsoServiceInterface = {
  async create(params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType> {
    return QsoPouchRepo.create(params);
  },
  async batchCreate(params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType[]> {
    return createQsosForMultiplePotaRefs(params);
  },
  async update(qso: QsoType): Promise<QsoType> {
    return QsoPouchRepo.update(qso);
  },
  async findAllByLogbook(logbookId: string): Promise<QsoType[]> {
    return QsoPouchRepo.findAllByLogbook(logbookId);
  },
  async countAllByLogbook(logbookId: string): Promise<number> {
    return QsoPouchRepo.countAllByLogbook(logbookId);
  },
  async deleteAllByLogbook(logbookId: string): Promise<QsoType[]> {
    return QsoPouchRepo.deleteAllByLogbook(logbookId);
  },
  async findAll(): Promise<QsoType[]> {
    return QsoPouchRepo.findAll();
  },
  async findOne(id: string): Promise<QsoType> {
    return QsoPouchRepo.findOne(id);
  },
  async delete(id: string): Promise<QsoType> {
    return QsoPouchRepo.delete(id);
  },
  async fetchAllWithPaging(page: number, limit: number, searchQuery: string): Promise<PaginationResult<QsoType>> {
    return QsoPouchRepo.fetchAllWithPaging(page, limit, searchQuery);
  }
};

export const createQsosForMultiplePotaRefs = async (params: { attrs: QsoType, logbook: LogbookType }): Promise<QsoType[]> => {
  const { attrs, logbook } = params;

  // Split the potaRef by comma, trim each value, and filter out empty strings
  const parks = attrs.potaRef!.split(',')
    .map(park => park.trim())
    .filter(park => park !== "");  // Remove empty values

  const createdQsos: QsoType[] = [];

  for (const park of parks) {
    // Create a new QSO for each valid park, modifying only the potaRef
    const qsoForPark = { ...attrs, potaRef: park };
    const createdQso = await QsoPouchRepo.create({ attrs: qsoForPark, logbook });
    createdQsos.push(createdQso);
  }

  return createdQsos;
};

export default QsoService;
