import type { LogbookType, QsoType } from "@/types";
import { QSO_ID_PREFIX } from "@/constants";

export function newQso(logbook: LogbookType, attrs: Partial<QsoType> = {}, operator: string): QsoType {
  const qso: QsoType = {
    _id: `${QSO_ID_PREFIX}:${logbook._id}:`, // Ensure to generate a unique ID here
    isDuplicate: false,
    rstRcvd: "59",
    rstSent: "59",
    lookedUp: false,
    call: "",
    operator,
    ...attrs,
  };
  return qso;
}

export default {
  newQso,
};
