import PouchDB from "pouchdb";
import PouchDBUpsert from "pouchdb-upsert";
import PouchDBFind from "pouchdb-find";

// Add PouchDB plugins
PouchDB.plugin(PouchDBUpsert);
PouchDB.plugin(PouchDBFind);

// Create PouchDB instance
const db = new PouchDB("hamrs.db");


export async function deleteCustomIndexes() {
  try {
    const indexes = await db.getIndexes();

    // Filter out the default "_all_docs" index
    const customIndexes = indexes.indexes.filter(index => index.ddoc !== null);

    // Delete each custom index by its design document
    for (const index of customIndexes) {
      const ddoc = index.ddoc;

      if (ddoc) {
        const designDoc = await db.get(ddoc);
        await db.remove(designDoc);
        console.log(`Deleted index: ${index.name} (Design Doc: ${ddoc})`);
      }
    }

    console.log("All custom indexes deleted.");
  } catch (err) {
    console.error("Error deleting indexes:", err);
  }
}
// Ensure the index is created asynchronously
export async function ensureIndexes() {
  await db.createIndex({
    index: { fields: ["qsoDateTime"] },  // Create index on qsoDateTime
  });
}

export default db;