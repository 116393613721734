import { XMLParser } from "fast-xml-parser";
import type { LookupServiceResponseType, LookupServiceProviderType } from "@/types";
import {
  ProviderCallsignNotFoundError,
  ProviderAuthenticationError,
  ProviderSessionExpiredError
} from "@/utils/errors/LookupServiceErrors";
import type { LookupServiceInterface } from "@/interfaces/LookupServiceInterface";
import LookupServiceResponseTransformer from "@/transformers/LookupServiceResponseTransformer";

const parser = new XMLParser();
const HAM_QTH_ENDPOINT = "https://www.hamqth.com/xml.php";

class HamQthService implements LookupServiceInterface {
  readonly providerName: LookupServiceProviderType = "HAMQTH";
  readonly requiresAuthentication: boolean = true;

  sessionKey: string;

  constructor(sessionKey: string = "") {
    this.sessionKey = sessionKey;
  }

  private normalizeResponse(response: any): LookupServiceResponseType {
    return LookupServiceResponseTransformer(response, this.providerName);
  }

  private parseXmlResponse(xmlData: string) {
    const parsedXml = parser.parse(xmlData);
    return parsedXml;
  }
  public async authenticate(
    username: string,
    password: string
  ): Promise<string> {
    const response = await fetch(
      `${HAM_QTH_ENDPOINT}?u=${username}&p=${password}`
    );
    const xmlData = await response.text();
    const parsedXml = this.parseXmlResponse(xmlData).HamQTH;

    if (parsedXml.session?.error) {
      this.handleHamQthError(parsedXml.session.error);
    }
    if (parsedXml.session?.session_id) {
      this.sessionKey = parsedXml.session.session_id;
    }

    return this.sessionKey;
  }
  public async lookupCallsign(callsign: string, sessionKey: string): Promise<LookupServiceResponseType> {
    if (!sessionKey) {
       throw new Error("Session key is required for this service");
    }
    const response = await fetch(
      `${HAM_QTH_ENDPOINT}?callsign=${callsign}&id=${sessionKey}&prg=HAMRS`
    );

    const xmlData = await response.text();
    const parsedXml = this.parseXmlResponse(xmlData);

    if (parsedXml?.HamQTH?.session?.error) {
      this.handleHamQthError(parsedXml?.HamQTH?.session?.error);
    }

    const normalizeResponse = this.normalizeResponse(parsedXml);
    return normalizeResponse;
  }


  private handleHamQthError(errorMessage: string): Error {
    switch (errorMessage) {
      case "Username or password missing":
      case "Wrong user name or password":
        throw new ProviderAuthenticationError(this.providerName, errorMessage);
      case "Session does not exist or expired":
        throw new ProviderSessionExpiredError(this.providerName, errorMessage);
      case "Callsign not found":
        throw new ProviderCallsignNotFoundError(this.providerName,errorMessage);
      default:
        throw new Error(errorMessage);
    }
  }
}

export default HamQthService;

// Sample Responses
//
// MISSING AUTH PARAMS
// https://www.hamqth.com/xml.php?u=username
//
// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <session>
//     <error>Username or password missing</error>
//   </session>
// </HamQTH>;

// INVALID AUTH PARAMS
// https://www.hamqth.com/xml.php?u=<bad_username>&p=<bad_password>
//
// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <session>
//     <error>Wrong user name or password</error>
//   </session>
// </HamQTH>;

// VALID AUTH PARAMS
// https://www.hamqth.com/xml.php?u=<good_username>&p=<good_password>
//
// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <session>
//     <session_id>b15cced1457dd4346e6538f36a24988e30300809</session_id>
//   </session>
// </HamQTH>;

// SESSION EXPIRED
// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <session>
//     <error>Session does not exist or expired</error>
//   </session>
// </HamQTH>;

// CALLSIGN NOT FOUND
// https://www.hamqth.com/xml.php?callsign=<bad_callsign>&id=<good_session_key>&prg=HAMRS
//

// <HamQTH xmlns="https://www.hamqth.com" version="2.8">
//   <session>
//     <error>Callsign not found</error>
//   </session>
// </HamQTH>
