// LogbookPouchRepo.ts
import PouchDB from "@/repos/PouchDB";
import type { LogbookType } from "@/types";
import type { BaseRepoInterface } from "@/interfaces";
import { v4 as uuidv4 } from "uuid";
import { LOGBOOK_ID_PREFIX } from "@/constants";

// NOTE: putting this method here lets our callers know we for sure have a
// delete and then it's happy.
interface LogbookRepoInterface extends BaseRepoInterface<LogbookType> {
  delete(id: string): Promise<LogbookType>;
  update(logbook: LogbookType): Promise<LogbookType>;
}

const LogbookPouchRepo: LogbookRepoInterface = {
  create: async (params: { attrs: Partial<LogbookType> } ): Promise<LogbookType> => {
    const logId = `${LOGBOOK_ID_PREFIX}:${uuidv4()}`;
    const createdAt = new Date().toISOString();
    const newLogbook = {
      _id: logId,
      createdAt,
      ...params.attrs,
    };

    await PouchDB.upsert(logId, () => newLogbook);
    return PouchDB.get(logId);
  },

  update: async (logbook: LogbookType): Promise<LogbookType> => {
    const updatedAt = new Date().toISOString();
    logbook.updatedAt = updatedAt;

    await PouchDB.upsert(logbook._id, () => logbook);
    return PouchDB.get(logbook._id);
  },


  findAll: async (): Promise<LogbookType[]> => {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${LOGBOOK_ID_PREFIX}:`,
      endkey: `${LOGBOOK_ID_PREFIX}:\ufff0`,
    });

    return result.rows
      .map((row) => row.doc as unknown as LogbookType)
      .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
  },

  findOne: async (id: string): Promise<LogbookType> => {
    return PouchDB.get(id);
  },

  delete: async (id: string): Promise<LogbookType> => {
    const doc = await PouchDB.get(id);
    await PouchDB.remove(doc);

    return Promise.resolve(doc as unknown as LogbookType)
  },
};

export default LogbookPouchRepo;
