import type { LogbookType, DuplicateLogbookType, QsoType } from '@/types';
import LogbookPouchRepo from '@/repos/LogbookPouchRepo';
import type { BaseServiceInterface } from '@/interfaces';
import QsoService from '@/services/QsoService';

interface LogbookServiceInterface extends BaseServiceInterface<LogbookType> {
  delete(id: string): Promise<LogbookType>;
  update(item: LogbookType): Promise<LogbookType>;
  findOne: (id: string) => Promise<LogbookType>;
  duplicate: (attrs: DuplicateLogbookType) => Promise<LogbookType>;
}

const LogbookService: LogbookServiceInterface = {
  create(params: { attrs: Partial<LogbookType> }): Promise<LogbookType> {
    return LogbookPouchRepo.create(params);
  },
  update(logbook: LogbookType): Promise<LogbookType> {
    return LogbookPouchRepo.update(logbook);
  },
  findAll(): Promise<LogbookType[]> {
    return LogbookPouchRepo.findAll();
  },
  findOne(id: string): Promise<LogbookType> {
    return LogbookPouchRepo.findOne(id);
  },

  delete(id: string): Promise<LogbookType> {
    // delete removes the logbook then all of its QSOs
    return LogbookPouchRepo.delete(id).then(async (logbook: LogbookType) => {
      await QsoService.deleteAllByLogbook(id);
      return logbook;
    });
  },
  async duplicate(attrs: DuplicateLogbookType): Promise<LogbookType> {
    try {
      const { originalLogbookId, logbookChanges, qsoChanges } = attrs;

      const qsosToDuplicate = await QsoService.findAllByLogbook(
        originalLogbookId
      );

      // Create a new logbook based on the changes
      const newLogbook = { ...logbookChanges };
      const createdLogbook = await LogbookPouchRepo.create({ attrs: newLogbook });

      // Create new QSOS based on the original ones with applied changes
      for (const qso of qsosToDuplicate) {
        const newQso = { ...qso, ...qsoChanges } as QsoType;
        await QsoService.create({ attrs: newQso, logbook: createdLogbook });
      }

      return createdLogbook;
    } catch (error) {
      console.error("Failed to duplicate logbook:", error);
      throw error; // Re-throw the error after logging it
    }
  },
};

export default LogbookService;
