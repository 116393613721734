import { createApp } from "vue";
import { plugin as formkitPlugin, defaultConfig } from "@formkit/vue";
import Toast from "vue-toastification";
import { createPinia } from "pinia";
import FloatingVue from 'floating-vue'

import 'floating-vue/dist/style.css'
import "./style.css";
import App from "./App.vue";
import router from "./router/router";
import formkitConfig from "./formkit.config";
import { initializeSentry } from "@/plugins/sentryInitializer";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { hamrsClientLogger } from "@/utils/loggers";
import { useSettingStore } from "@/stores/SettingStore";
import { pwaEnabled } from "./config";
import "leaflet/dist/leaflet.css";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/500.css";
import "@fontsource/roboto-mono/600.css";
import "@fontsource/roboto-mono/700.css";
import "vue-toastification/dist/index.css";
import './cypressServiceInjector';
import { APP_VERSION } from './constants';
const app = createApp(App);
const pinia = createPinia();
const ENVIRONMENT = import.meta.env.MODE;


const toastOptions = {
  containerClassName: "hamrs-toast-container",
  filterBeforeCreate: (toast: typeof Toast) => {
    const settingStore = useSettingStore(); // Called here so we can be sure pinia is active.
    if (!settingStore.displayToasts) {
      return false;
    }
    return toast;
  },
};
hamrsClientLogger("Version:", APP_VERSION);
hamrsClientLogger("Environment:", ENVIRONMENT);
hamrsClientLogger("PWA Enabled:", pwaEnabled);

if (ENVIRONMENT != "development") {
  initializeSentry(app, router);
}

app
  .use(formkitPlugin, defaultConfig(formkitConfig))
  .use(router)
  .use(pinia)
  .use(FloatingVue)
  .use(autoAnimatePlugin)
  .use(Toast, toastOptions)
  .mount("#app");
