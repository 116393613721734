import { LOGBOOK_TEMPLATE_NAMES } from "@/constants";
import type { TemplateKey } from "@/types/LogbookType";
import type { ProfileInterface } from "@/interfaces";

export function templateTitleFor(template: TemplateKey): string {
  return LOGBOOK_TEMPLATE_NAMES[template.toUpperCase()];
}

export function formatUTCDateToLocale(timestamp: string) {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', { dateStyle: "short" }).format(date);
}
export function trimObjectValues(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Not an object, return as is
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => trimObjectValues(item)); // Recursively trim array elements
  }
  const trimmedObj: Record<string, any> = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else if (typeof obj[key] === 'object') {
      trimmedObj[key] = trimObjectValues(obj[key]); // Recursively trim nested objects
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
}


export const normalizeProfile = (profile: ProfileInterface): ProfileInterface => {
  profile = trimObjectValues(profile);
  return {
    ...profile,
    operator: {
      ...profile.operator,
      callsign: profile.operator.callsign.toUpperCase().trim(),
      firstName: toTitleCase(profile.operator.firstName).trim(),
      lastName: toTitleCase(profile.operator.lastName).trim(),
    },
  };
}

export function toTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function gridFormatter(grid: string): string {
  let formatted = grid.trim();
  formatted =
    formatted.substring(0, 2).toUpperCase() +
    formatted.substring(2, 4) +
    formatted.substring(4, 8).toLowerCase();
  return formatted;
}

export function formatMhz(freq: number | string): string {
  const numericFreq = Number(freq);

  if (Number.isNaN(numericFreq)) return '';

  const positiveFreq = Math.abs(numericFreq);
  const decimalPart = positiveFreq.toString().split('.')[1];

  if (!decimalPart || decimalPart.length <= 3) {
    return positiveFreq.toFixed(3);
  }

  return positiveFreq.toString();
}